.history__about-title {
  font-size: 44px;
  font-weight: 400;
  margin: 0;
}


@media screen and (max-width: 1279px) {
  .history__about-title {
    font-size: 40px;
  }
}