.card-info__image {
  object-fit: cover;
  width: 560px;
  height: 560px;
  cursor: zoom-in;
}

@media screen and (max-width: 980px) {
  .card-info__image {
    width: 90%;
  }
}

@media screen and (max-width: 690px) {
  .card-info__image {
    width: 90%;
    height: 480px;
  }
}

@media screen and (max-width: 630px) {
  .card-info__image {
    height: 460px;
  }
}

@media screen and (max-width: 520px) {
  .card-info__image {
    height: 400px;
  }
}

@media screen and (max-width: 420px) {
  .card-info__image {
    height: 350px;
  }
}

@media screen and (max-width: 350px) {
  .card-info__image {
    height: 290px;
  }
}