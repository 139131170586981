.gallery-block {
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1249px) {
  .gallery-block {
    width: 90%;
  }
}

@media screen and (max-width: 850px) {
  .gallery-block {
    margin-top: 2px;
  }
}