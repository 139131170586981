.gallery__items {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1249px) {
  .gallery__items {
    justify-content: center;
    align-items: center;
  }
}