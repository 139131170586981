.more__item {
  height: 340px;
  width: 100%;
  max-width: 370px;
  border: none;
  border-radius: 20px;
}

@media screen and (max-width: 900px) {
  .more__item {
    width: 100%;
  }
}