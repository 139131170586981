.catalog-classes__boards {
  background-image: url(../../../../../images/children.jpg);
  background-size: cover;
  grid-column: 2/4;
  grid-row: 2/3;
}

@media screen and (max-width: 1020px) {
  .catalog-classes__boards{
    grid-column: 2;
    grid-row: 2;
  }
}