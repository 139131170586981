.cart__item-quantity-container {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 35px;
}

@media screen and (max-width: 1249px) {
  .cart__item-quantity-container {
    gap: 20px;
  }
}

@media screen and (max-width: 950px) {
  .cart__item-quantity-container {
    gap: 10px;
  }
}

@media screen and (max-width: 430px) {
  .cart__item-quantity-container {
    gap: 7px;
  }
}