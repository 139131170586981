.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(360px,1fr));
  gap: 40px;
  width: 100%;
  margin-top: 50px;
  max-width: 1160px;
  box-sizing: border-box;
  row-gap: 50px;
}

@media screen and (max-width: 1249px) {
  .card-list {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
  }
}

@media screen and (max-width: 1067px) {
  .card-list {
    gap: 25px;
    max-width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

@media screen and (max-width: 755px) {
  .card-list {
    max-width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
}

@media screen and (max-width: 560px) {
  .card-list {
    max-width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}