.formula__photo2 {
  background-image: url(../../../../../images/formula2.png);
  background-size: cover;
}

@media screen and (max-width: 1160px) {
  .formula__photo2 {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}

@media screen and (max-width: 768px) {
  .formula__photo2 {
    grid-column: 2/1;
    grid-row: 3/2;
  }
}

@media screen and (max-width: 520px) {
  .formula__photo2 {
    grid-column: 1/3;
    grid-row: 2;
  }
}