.history__slogan {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 1279px) {
  .history__slogan {
    width: 90%;
  }
}