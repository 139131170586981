.bestsellers__title {
  font-size: 50px;
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 1249px) {
  .bestsellers__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 750px) {
  .bestsellers__title {
    font-size: 32px;
  }
}