.cart__total-count {
  font-size: 26px;
  font-weight: 500;
  width: 150px;
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 950px) {
  .cart__total-count {
    width: 114px;
    font-size: 22px;
  }
}

@media screen and (max-width: 490px) {
  .cart__total-count {
    width: 100px;
    font-size: 20px;
  }
}

@media screen and (max-width: 430px) {
  .cart__total-count {
    width: 84px;
    font-size: 18px;
  }
}

@media screen and (max-width: 380px) {
  .cart__total-count {
    font-size: 14px;
    width: 114px;
  }
}