.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e1e1e1;
  margin-top: 140px;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

@media screen and (max-width: 540px) {
  .footer {
    margin-top: 40px;
  }
}