.catalog__text {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 959px) {
  .catalog__text {
    height: 310px;
  }
}

@media screen and (max-width: 666px) {
  .catalog__text {
    height: 290px;
  }
}