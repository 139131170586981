.card-info__preview-container {
  display: flex;
  width: 560px;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

@media screen and (max-width: 980px) {
  .card-info__preview-container {
    width: 90%;
    justify-content: center;
  }
}