.filter-button__item {
  padding: 0;
  width: 223px;
  height: 63px;
  border-radius: 10px;
  border: solid 2px var(--accent-color);
  color: var(--accent-color);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-button__item:hover {
  color: var(--secondary-text-color);
  border: none;
  background-color: var(--accent-color);
}
