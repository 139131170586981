.footer__title {
  margin: 30px 0;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .footer__title {
    text-align: center;
  }
}