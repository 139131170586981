.catalog__item {
  width: 100%;
  max-width: 1160px;
  height: 400px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

@media screen and (max-width: 959px) {
  .catalog__item {
    height: 310px;
  }
}

@media screen and (max-width: 666px) {
  .catalog__item {
    height: 290px;
  }
}