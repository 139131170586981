.presentation__block {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}

@media screen and (max-width: 1160px) {
  .presentation__block {
    margin-top: 24px;
  }
}

@media screen and (max-width: 900px) {
  .presentation__block {
    flex-direction: column-reverse;
    gap: 12px;
  }
}