.kitchens__text-block {
  width: 555px;
  height: 555px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--accent-color);
}

@media screen and (max-width: 880px) {
  .kitchens__text-block {
    width: 100%;
    margin: 0 12px;
    height: 100%;
  }
}