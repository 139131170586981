.houses__gallery-text {
  font-weight: 500;
  font-size: 15px;
  color: var(--secondary-text-color);
  padding: 20px 40px 0;
  margin: 0;
}

.houses__gallery-text:first-child {
  margin-top: 30px;
}

@media screen and (max-width: 935px) {
  .houses__gallery-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 839px) {
  .houses__gallery-text {
    font-size: 12px;
    padding: 10px 20px 0;
  }
}