.formula {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}


/* @media screen and (max-width: 1249px) {
  .formula {
    padding: 0 12px;
  }
} */