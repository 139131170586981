.comments__block {
  display: flex;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  gap: 25px;
}

@media screen and (max-width: 1160px) {
  .comments__block {
    justify-content: center;
    flex-wrap: wrap;
  }
}