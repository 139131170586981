.bestsellers__showcase {
  display: flex;
  max-width: 1160px;
  width: 100%;
  gap: 40px;
  margin-top: 50px;
  justify-content: center;
}

@media screen and (max-width: 1249px) {
  .bestsellers__showcase {
    margin-top: 12px;
  }
}