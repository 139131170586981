.history__about-text {
  color: #333333;
  font-size: 44px;
  font-weight: 400;
  margin: 0;
  max-width: 79%;
}

@media screen and (max-width: 1279px) {
  .history__about-text {
    font-size: 40px;
  }
}