.catalog-classes__kitchens {
  background-image: url(../../../../../images/kitchen2.jpg);
  background-size: cover;
  grid-column: 1/2;
  grid-row: 1/3;
  pointer-events: auto;
}

@media screen and (max-width: 1020px) {
  .catalog-classes__kitchens {
    grid-column: 1;
    grid-row: 2;
  }
}