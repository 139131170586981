.quiz-exit__button {
  border: 1px var(--accent-color) solid;
  border-radius: 10px;
  color: var(--primary-text-color);
  font-size: 28px;
  font-weight: 500;
  background-color: transparent;
  padding: 20px 60px;
  margin: 40px 0;
}

.quiz-exit__button:hover {
  color: var(--secondary-text-color);
  /* border: none; */
  background-color: var(--accent-color);
}

@media screen and (max-width: 480px) {
  .quiz-exit__button {
    font-size: 28px;
  }
}