.not-found__button {
  border: none;
  color: var(--primary-text-color);
  font-size: 24px;
  font-weight: 500;
  background-color: transparent;
  padding-bottom: 20px;
}

.not-found__button:last-child {
  padding-bottom: 40px;
}