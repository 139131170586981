.navigation__phone {
  margin: 0 0 5px;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 959px) {
  .navigation__phone {
    font-size: 16px;
  }
}