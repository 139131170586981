.history__story-container {
  width: 555px;
  height: 555px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1279px) {
  .history__story-container {
    width: 90%;
    height: 90%;
  }
}