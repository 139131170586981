.comments__item {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-radius: 20px;
  background-position: center center;
  border-color: var(--border-color);
  border-style: solid;
  height: 390px;
  width: 100%;
  max-width: 368px;
}