.quiz-exit__text {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin: 30px 0;
  color: var(--accent-color);
  width: 92%;
}

@media screen and (max-width: 768px) {
  .quiz-exit__text {
    font-size: 40px;
    font-weight: 500;
    margin: 40px 0;
    width: 92%;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .quiz-exit__text {
    font-size: 28px;
  }
}