.now__title {
  color: #333333;
  font-size: 44px;
  font-weight: 400;
  margin: 0;
  max-width: 90%;
  width: 100%;
}

@media screen and (max-width: 1160px) {
  .now__title {
    padding: 0 24px;
  }
}