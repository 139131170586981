.banner {
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 650px;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .banner {
    height: 550px;
  }
}

@media screen and (max-width: 750px) {
  .banner {
    height: 480px;
  }
}