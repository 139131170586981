.cart__image {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

@media screen and (max-width: 950px) {
  .cart__image  {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 560px) {
  .cart__image  {
    width: 0;
    position: absolute;
    visibility: hidden;
  }
}