.cart__item-price {
  font-size: 26px;
  font-weight: 500;
  width: 100px;
  text-align: center;
}

@media screen and (max-width: 950px) {
  .cart__item-price {
    font-size: 22px;
  }
}

@media screen and (max-width: 490px) {
  .cart__item-price {
    font-size: 20px;
  }
}

@media screen and (max-width: 430px) {
  .cart__item-price {
    font-size: 18px;
  }
}

@media screen and (max-width: 380px) {
  .cart__item-price {
    font-size: 14px;
  }
}