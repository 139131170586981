.footer__logo-container {
  width: 100%;
  max-width: 1160px;
  display: flex;
  gap: 5px;
  align-items: flex-end;
  margin: 30px 0 0;
}

@media screen and (max-width: 1100px) {
  .footer__logo-container {
    justify-content: center;
  }
}