.presentation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

@media screen and (max-width: 1160px) {
  .presentation {
    margin-top: 32px;
  }
}