.carousel {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 1249px) {
  .carousel {
    width: 90%;
  }
}