.formula__photo4 {
  background-image: url(../../../../../images/formula4.png);
  background-size: cover;
  grid-column: 2/4;
  grid-row: 2/3;
}

@media screen and (max-width: 1160px) {
  .formula__photo4 {
    grid-column: 1/3;
    grid-row: 3/4;
  }
}

@media screen and (max-width: 768px) {
  .formula__photo4 {
    grid-column: 1/3;
    grid-row: 3/3;
  }
}

@media screen and (max-width: 520px) {
  .formula__photo4 {
    grid-column: 1/3;
    grid-row: 4;
  }
}