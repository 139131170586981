.houses__gallery-pic1 {
  background-image: url(../../../../images/houses1.jpg);
  background-size: cover;
}

@media screen and (max-width: 839px) {
  .houses__gallery-text {
    grid-column: 2/4;
    grid-row: 1/2;
  }
}