.banner__subtitle {
  color: var(--secondary-accent-color);
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  margin: 0;
  max-width: 500px;
  text-shadow: 1px 1px var(--text-shadow-color);
}

@media screen and (max-width: 750px) {
  .banner__subtitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 500px) {
  .banner__subtitle {
    font-size: 32px;
  }
}