.gallery-block__title-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white;
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  bottom: 2.5%;
  right: 2.5%;
  opacity: 0.95;
}