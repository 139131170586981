.footer__contacts {
  max-width: 240px;
  width: 100%;
  padding: 0 10px;
}

@media screen and (max-width: 1100px) {
  .footer__contacts {
    text-align: center;
  }
}