.popup__container {
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  color: black;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 460px) {
  .popup__container {
    width: calc(100% * (282 / 320));
    }
  }
