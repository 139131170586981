.history__image-container {
  width: 555px;
  height: 555px;
  border: none;
  border-radius: 20px;
  object-fit: cover;
}

@media screen and (max-width: 1279px) {
  .history__image-container {
    width: 90%;
    height: 90%;
  }
}