.more__delivery {
  background-image: url(../../../../../images/delivery.png);
  background-size: cover;
}

@media screen and (max-width: 540px) {
  .more__delivery {
    width: 90%;
    max-width: 500px;
  }
}