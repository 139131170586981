.footer__menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1160px;
  gap: 20px;
  margin-top: 10px;
}

@media screen and (max-width: 1100px) {
  .footer__menu {
    justify-content: center;
  }
}