.card-info__preview-image {
  object-fit: cover;
  width: 108px;
  height: 108px;
}

@media screen and (max-width: 600px) {
  .card-info__preview-image {
    width: 80px;
    height: 80px;
  }
}