.catalog__title {
  width: 100%;
  max-width: 1160px;
  color: var(--primary-text-color);
  font-style: normal;
  font-weight: 500;
  font-size:46px;
  margin: 50px 0 0;
}

@media screen and (max-width: 1249px) {
  .catalog__title {
    max-width: 90%;
  }
}