.presentation__list {
  width: 100%;
  max-width: 558px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .presentation__list {
    flex-direction: row;
    gap: 1vw;
    max-width: 100%;
    width: calc(100% - 24px);
    margin: 0 12px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* column-gap: 2vw; */
  }
}

@media screen and (max-width: 644px) {
  .presentation__list {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
}