.not-found__404 {
  font-size: 240px;
  font-weight: 500;
  margin: 60px 0 0;
}


@media screen and (max-width: 768px) {
  .not-found__404 {
    font-size: 200px;
    margin: 40px 0;
  }
}

@media screen and (max-width: 480px) {
  .not-found__404 {
    margin: 60px 0 40px 0;
    font-size: 160px;
  }
}