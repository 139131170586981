.mission__image-container {
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  max-width: 615px;
  height: 496px;
}

@media screen and (max-width: 1160px) {
  .mission__image-container {
    max-width: 400px;
  }
}

@media screen and (max-width: 910px) {
  .mission__image-container {
    max-width: 615px;
  }
}