.card__image {
  width: 100%;
  height: 330px;
  object-fit: cover;
  border-radius: 10px;
}


@media screen and (max-width: 1249px) {
  .card__image {
    height: 380px;
  }
}

@media screen and (max-width: 890px) {
  .card__image {
    height: 320px;
  }
}

@media screen and (max-width: 755px) {
  .card__image {
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .card__image {
    height: 310px;
  }
}