.mission__button {
  background-color: var(--accent-color);
  border-radius: 10px;
  border: none;
  width: 235px;
  height: 60px;
  color: var(--secondary-text-color);
  font-weight: 600;
  font-size: 18px;
  margin-top: 60px;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .mission__button {
    font-size: 16px;
  }
}