.kitchens__text {
  margin: 50px 0 0;
  width: 100%;
  max-width: 1160px;
}

@media screen and (max-width: 1160px) {
  .kitchens__text {
    width: 90%;
    box-sizing: border-box;
  }
}