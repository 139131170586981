.stage {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1160px;
  margin-top: 70px;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .stage {
    margin-top: 32px;
  }
}