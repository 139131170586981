.card__buy-button {
  color: #778a64;
  font-size: 16px;
  line-height: 1.20;
  font-weight: 500;
  border-width: 1px;
  border-radius: 10px;
  background-color: #ffffff;
  background-position: center center;
  border-color: #8f9e81;
  border-style: solid;
  width: 124px;
  height: 45px;
}

.card__buy-button:hover {
  color: var(--secondary-text-color);
  border: none;
  background-color: var(--accent-color);
}

@media screen and (max-width: 750px) {
  .card__buy-button {
    font-size: 14px;
  }
}