.navigation__item {
  text-decoration: none;
  color: var(--primary-text-color);
  font-weight: 400;
  font-size: 16px;
  position: relative;
  border: none;
  border-radius: 3px;
}

@media screen and (max-width: 959px) {
  .navigation__item {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .navigation__item {
    font-size: 18px;
    font-weight: 500;
  }
}