.formula__photo3 {
  background-image: url(../../../../../images/chair-buk.png);
  background-size: cover;
}

@media screen and (max-width: 1160px) {
  .formula__photo3 {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}

@media screen and (max-width: 768px) {
  .formula__photo3 {
    grid-column: 2/2;
    grid-row: 2/3;
  }
}

@media screen and (max-width: 520px) {
  .formula__photo3 {
    grid-column: 1/3;
    grid-row: 3;
  }
}