.kitchens__quiz-image {
  width: 555px;
  height: 555px;
  border: none;
  border-radius: 20px;
}

@media screen and (max-width: 880px) {
  .kitchens__quiz-image {
    width: calc(100% - 24px);
    margin: 0 12px;
    height: 100%;
    box-sizing: border-box;
  }
}