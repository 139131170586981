.history__fundamental {
  display: flex;
  align-items: flex-end;
  max-width: 420px;
}

@media screen and (max-width: 1160px) {
  .history__fundamental {
    padding: 24px;
  }
}