.besrsellers__title-block {
  display: flex;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1249px) {
  .besrsellers__title-block {
    width: 90%;
    flex-wrap: wrap;
    gap: 12px;
  }
}