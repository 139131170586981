.quiz-exit__title {
  font-size: 120px;
  font-weight: 500;
  margin: 60px 0;
  width: 92%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .quiz-exit__title {
    font-size: 62px;
    margin: 40px 0;
  }
}

@media screen and (max-width: 480px) {
  .quiz-exit__title {
    margin: 60px 0 40px 0;
    font-size: 40px;
  }
}