.cart__items {
  width: 100%;
  max-width: 1160px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1249px) {
  .cart__items {
    width: 90%;
  }
}