.formula__photo1 {
  background-image: url(../../../../../images/formula1.png);
  background-size: cover;
  grid-column: 1/2;
  grid-row: 1/3;
  pointer-events: auto;
}

@media screen and (max-width: 1160px) {
  .formula__photo1 {
    grid-column: 1/1;
    grid-row: 1/3;
  }
}

@media screen and (max-width: 768px) {
  .formula__photo1 {
    grid-column: 1/3;
    grid-row: 1;
  }
}

