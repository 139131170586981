.stage__image-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1160px;
  gap: 50px;
  margin-top: 50px;
}

@media screen and (max-width: 1160px) {
  .stage__image-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    margin-top: 32px;
  }
}