.cart__order {
  width: 550px;
  background-color: #abb9a4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  color: var(--secondary-text-color);
  text-shadow: 1px 1px darkgrey;
  padding: 30px;
  margin: 0;
}

@media screen and (max-width: 980px) {
  .cart__order {
    width: 80%;
  }
}