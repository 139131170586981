.contacts__list-item {
  border: none;
  background-color: transparent;
  color: var(--secondary-text-color);
  text-shadow: 1px 1px darkgrey;
  font-size: 20px;
  font-weight: 600;
  text-align: start;
  padding-top: 10px;
  cursor: pointer;
}