.more__title {
  width: 100%;
  max-width: 1160px;
  color: var(--primary-text-color);
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  margin: 0;
}

@media screen and (max-width: 540px) {
  .more__title {
    text-align: center;
  }
}