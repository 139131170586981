.houses__filter {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1249px) {
  .houses__filter {
    visibility: hidden;
    height: 0;
    width: 90%;
  }
}