.card-info__buy-button {
  color: var(--secondary-text-color);
  font-size: 16px;
  line-height: 1.20;
  font-weight: 500;
  border-width: 1px;
  border-radius: 10px;
  background-color: var(--accent-color);
  background-position: center center;
  border-color: #8f9e81;
  border-style: solid;
  width: 155px;
  height: 55px;
  cursor: pointer;
}