.popup__call-me-fieldset {
  padding: 0;
  border: none;
  margin-top: 19px;
}


@media screen and (max-width: 360px) {
  .popup__call-me-fieldset {
    margin-top: 43px;
  }
}