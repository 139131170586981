.more__item-title {
  font-size: 20px;
  font-weight: 400;
  padding-left: 30px;
  margin: 30px 0 0;
  color: var(--secondary-text-color);
  font-size: 25px;
  font-weight: 500;
  text-shadow: 1px 1px #a3a3a3;
  display: flex;
  align-items: flex-end;
  height: 85%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}