.card {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  max-height: 661px;
}

@media screen and (max-width: 756px) {
  .card {
    max-width: 700px;
    max-height: 900px;
  }
}