.navigation__container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

@media screen and (max-width: 769px) {
  .navigation__container {
    position: relative;
    display: flex;
    height: 100%;
    width: 50%;
    max-width: 520px;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-bg);
    box-sizing: border-box;
    padding: 159px 0 90px;
    /* left: 50%; */
  }
}