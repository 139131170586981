.carousel__slide {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.slide-transition {
  display: flex;
}

.slide-transition-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.slide-transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-transition-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.carousel__slide-item {
  flex-shrink: 0;
}

.carousel__slide-inner {
  display: flex;
  gap: 40px;
}
