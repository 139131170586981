.faq-group__btn {
  width: 33px;
  height: 33px;
  border-radius: 20px;
  border: none;
  background-color: var(--accent-color);
  cursor: pointer;
  transition: transform .5s ease-in-out;
  position: absolute;
  right: 0;
  top: 12px;
}

.faq-group__btn:hover {
  background-color: var(--accent-color);
  transform: rotate(45deg);
}