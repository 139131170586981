.presentation__pic {
  width: 555px;
  height: 569px;
  border-radius: 20px;
  object-fit: cover;
}

@media screen and (max-width: 900px) {
  .presentation__pic {
    width: calc(100% - 24px);
    margin: 0 12px;
  }
}