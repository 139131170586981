.catalog__item-subtitle {
  color: var(--secondary-text-color);
  font-size: 60px;
  font-weight: 600;
  margin: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1249px) {
  .catalog__item-subtitle {
    font-size: 50px;
  }
}

