.banner__video {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  visibility: hidden;
}

@media screen and (max-width: 1280px) {
  .banner__video {
    margin-top: 80px;
  }
}

@media screen and (max-width: 950px) {
  .banner__video {
    visibility: hidden;
    position: absolute;
  }
}