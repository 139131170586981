.card-info__image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 980px) {
  .card-info__image-container {
    margin-top: 20px;
  }
}