.footer__social {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

@media screen and (max-width: 1100px) {
  .footer__social {
    justify-content: center;
  }
}