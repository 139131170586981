.footer__item {
  margin: 6px 0;
  text-decoration: none;
  color: var(--primary-rtext-color);
  font-weight: 400;
  font-size: 16px;
}

@media screen and (max-width: 1100px) {
  .footer__item {
    text-align: center;
  }
}