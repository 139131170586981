.quiz-exit {
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .quiz-exit {
    margin-top: 100px;
  }
}

@media screen and (max-width: 480px) {
  .quiz-exit {
    margin-top: 60px;
  }
}