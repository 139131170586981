.contacts__blocks {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}

@media screen and (max-width: 780px) {
  .contacts__blocks {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}