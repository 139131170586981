.now__item {
  max-width: 275px;
  width: 100%;
  height: 263px;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-radius: 20px;
  background-position: center center;
  border-color: var(--border-color);
  border-style: solid;
}