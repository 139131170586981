.cart__total-text {
  width: 100%;
  max-width: 335px;
  font-size: 14px;
  padding-left: 15px;
  font-size: 21px;
  font-weight: 700;
  margin: 0;
}

@media screen and (max-width: 950px) {
  .cart__total-text {
    max-width: 210px;
  }
}

@media screen and (max-width: 720px) {
  .cart__total-text {
    max-width: 150px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 400px) {
  .cart__total-text {
    font-size: 20px;
    max-width: 140px;
  }
}