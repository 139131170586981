.collage__items {
  grid-gap: 16px;
  align-items: center;
  display: grid;
  grid-auto-rows: minmax(0,320px);
  grid-template-columns: 1.5fr 1fr 1fr;
  height: 912px;
  margin-top: 30px;
  max-width: 1160px;
  width: 100%;
}