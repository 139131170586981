.cart__info {
  width: 100%;
  max-width: 335px;
  padding-left: 10px;
}

@media screen and (max-width: 950px) {
  .cart__info {
    max-width: 210px;
  }
}

@media screen and (max-width: 720px) {
  .cart__info {
    max-width: 150px;
  }
}