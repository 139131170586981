.houses__advantages {
  display: flex;
  gap: 11px;
  margin-top: 20px;
}

@media screen and (max-width: 1249px) {
  .houses__advantages {
    visibility: hidden;
    height: 0;
    width: 90%;
  }
}