.banner__text {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .banner__text {
    height: 550px;
    max-height: 370px;
    max-width: 500px;
  }
}

@media screen and (max-width: 1280px) {
  .banner__text {
    max-width: 700px;
  }
}

