.banner__title {
  color: var(--secondary-text-color);
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  max-width: 600px;
  text-align: left;
  margin: 0;
  text-shadow: 1px 1px var(--text-shadow-color);
}

@media screen and (max-width: 750px) {
  .banner__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 500px) {
  .banner__title {
    font-size: 32px;
  }
}