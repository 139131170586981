.mission__subtitle {
  color: rgb(103, 77, 65);
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  margin: 0;
}

@media screen and (max-width: 1249px) {
  .mission__subtitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 750px) {
  .mission__subtitle {
    font-size: 32px;
  }
}