.now__button {
  background-color: var(--accent-color);
  border-radius: 10px;
  border: none;
  width: 215px;
  height: 60px;
  color: var(--secondary-text-color);
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}