.navigation__cart-button {
  width: 40px;
  height: 40px;
  background-image: url(../../../images/shopping-cart.svg);
  border: none;
  margin: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
}