.cart__item-minus {
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .cart__item-minus {
    width: 32px;
  }
}

@media screen and (max-width: 490px) {
  .cart__item-minus {
    width: 25px;
  }
}

@media screen and (max-width: 430px) {
  .cart__item-minus {
    width: 20px;
  }
}