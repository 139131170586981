.houses__temp-text {
  text-align: center;
  color: #333333;
  font-size: 44px;
  font-weight: 400;
  margin: 30px 0;
  width: 100%;
  max-width: 1160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1249px) {
  .houses__temp-text{
    font-size: 40px;
    width: 90%;
  }
}

@media screen and (max-width: 650px) {
  .houses__temp-text{
    font-size: 36px;
  }
}