.navigation__cart-counter {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: #dba865;
  color: var(--primary-text-color);
  position: absolute;
  top: -5px;
  left: 15px;
}