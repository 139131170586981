.presentation__list-item {
  height: 110px;
  border: 1px solid var(--accent-color);
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  color: var(--accent-color);
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0 0;
}

.presentation__list-item:hover {
  border: none;
  color: var(--secondary-text-color);
  background-color: var(--accent-color);
  ;
}

@media screen and (max-width: 900px) {
  .presentation__list-item {
    min-height: 240px;
    width: 25%;
    border-radius: 10px;
    padding: 8px 20px;
  }
}

@media screen and (max-width: 644px) {
  .presentation__list-item {
    width: calc(100% - 24px);
    min-height: 80px;
    height: 100%;
  }
}