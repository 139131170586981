.popup__save-button_disabled {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  box-sizing: border-box;
  color: #000;
  opacity: 0.2;
  pointer-events: none;
}

@media screen and (max-width: 420px) {
  .popup__save-button_disabled {
    margin: 45px auto 25px;
    height: 46px;
    font-size: 14px;
    line-height: 17px;
  }
}