.formula__items {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  grid-auto-rows: minmax(0px, 320px);
  width: 100%;
  height: 600px;
  max-width: 1160px;
  margin-top: 50px;
}

@media screen and (max-width: 1249px) {
  .formula__items {
    width: 90%;
  }
}

@media screen and (max-width: 1160px) {
  .formula__items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
    grid-gap: 8px;
  }
}

@media screen and (max-width: 769px) {
  .formula__items {
    grid-gap: 12px;
  }
}

@media screen and (max-width: 520px) {
  .formula__items {
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}