.video {
  margin-top: 70px;
  width: 1160px;
  height: 652px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  background-image: url(../../images/history.png);
  cursor:pointer;
}