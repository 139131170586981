.card__details-button {
  color: var(--secondary-text-color);
  background-color: var(--accent-color);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border: none;
  width: 200px;
  height: 60px;
  font-size: 16px;
  line-height: 1.20;
  font-weight: 500;
  cursor: pointer;
}

.card__details-button:hover {
  color: var(--accent-color);
  border: 1px solid #8f9e81;
  background-color: var(--secondary-text-color);
}

@media screen and (max-width: 750px) {
  .card__details-button {
    font-size: 14px;
  }
}