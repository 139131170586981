.not-found__text {
  font-size: 48px;
  font-weight: 500;
  margin-top: 30px;
  color: var(--accent-color);
  /* width: 92%; */
  text-align: center;
}

@media screen and (max-width: 768px) {
  .not-found__text {
    font-size: 40px;
    margin: 40px 0;
    /* width: 92%; */
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .not-found__text {
    font-size: 28px;
  }
}