.gallery-block__image-container {
  width: 100%;
  max-width: 1160px;
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 0fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr; 
  gap: 5px 5px; 
  grid-template-areas: 
    "gallery-block__title-pic gallery-block__pic2 gallery-block__pic3 ."
    "gallery-block__pic4 gallery-block__pic2 gallery-block__pic5 ."
    "gallery-block__pic6 gallery-block__pic7 gallery-block__pic8 ."
    "gallery-block__pic6 gallery-block__pic9 gallery-block__pic8 ."; 
}

/* @media screen and (max-width: 1249px) {
  .gallery-block__image-container {
    width: 90%;
  }
} */

.gallery-block__title-pic {
  position: relative;
  grid-area: 1 / 1 / 2 / 2;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 246px;
}

.gallery-block__pic2 {
  grid-area: 1 / 2 / 3 / 3;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 497px;
}

.gallery-block__pic4 {
  grid-area: 2 / 1 / 3 / 2;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 246px;
}

.gallery-block__pic3 {
  grid-area: 1 / 3 / 2 / 4;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 246px;
}

.gallery-block__pic5 {
  grid-area: 2 / 3 / 3 / 4;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 246px;
}

.gallery-block__pic6 {
  grid-area: 3 / 1 / 5 / 2;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 497px;
}
.gallery-block__pic7 {
  grid-area: 3 / 2 / 4 / 3;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 246px;
}
.gallery-block__pic8 {
  grid-area: 3 / 3 / 5 / 4;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 497px;
}
.gallery-block__pic9 {
  grid-area: 4 / 2 / 5 / 3;
  box-sizing: border-box;
  object-fit: cover;
  justify-self: initial;
  align-self: initial;
  max-width: 385px;
  max-height: 246px;
}



@media screen and (max-width: 850px) {
  .gallery-block__image-container {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap: 2px 2px; 
  }
  .gallery-block__title-pic { grid-area: 1 / 1 / 2 / 2 }
  .gallery-block__pic2 { grid-area: 1 / 2 / 3 / 3 }
  .gallery-block__pic3 { grid-area: 2 / 1 / 3 / 2 }
  .gallery-block__pic6 { grid-area: 3 / 1 / 5 / 2 }
  .gallery-block__pic5 { grid-area: 3 / 2 / 4 / 3 }
  .gallery-block__pic4 { grid-area: 4 / 2 / 5 / 3 }
  .gallery-block__pic7 { grid-area: 5 / 1 / 6 / 2 }
  .gallery-block__pic9 { grid-area: 6 / 1 / 7 / 2 }
  .gallery-block__pic8 { grid-area: 5 / 2 / 7 / 3 }
}

@media screen and (max-width: 620px) {
  .gallery-block__image-container {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap: 2px 2px; 
  }
  .gallery-block__title-pic { grid-area: 1 / 1 / 2 / 2; max-width: 100%; max-height: 246px }
  .gallery-block__pic2 { grid-area: 1 / 2 / 2 / 3; max-width: 100%; max-height: 246px }
  .gallery-block__pic3 { grid-area: 2 / 1 / 3 / 3; max-width: 100%; max-height: 246px }
  .gallery-block__pic6 { grid-area: 4 / 1 / 5 / 3; max-width: 100%; max-height: 246px }
  .gallery-block__pic5 { grid-area: 3 / 2 / 4 / 3; max-width: 100%; max-height: 246px }
  .gallery-block__pic4 { grid-area: 3 / 1 / 4 / 2; max-width: 100%; max-height: 246px }
  .gallery-block__pic7 { grid-area: 5 / 1 / 6 / 2; max-width: 100%; max-height: 246px }
  .gallery-block__pic9 { grid-area: 6 / 1 / 7 / 3; max-width: 100%; max-height: 246px }
  .gallery-block__pic8 { grid-area: 5 / 2 / 6 / 3; max-width: 100%; max-height: 246px }
}


@media screen and (max-width: 480px) {
  .gallery-block__title-pic { max-height: 246px }
  .gallery-block__pic2 { max-height: 220px }
  .gallery-block__pic3 { max-height: 220px }
  .gallery-block__pic6 { max-height: 220px }
  .gallery-block__pic5 { max-height: 220px }
  .gallery-block__pic4 { max-height: 220px }
  .gallery-block__pic7 { max-height: 220px }
  .gallery-block__pic9 { max-height: 220px }
  .gallery-block__pic8 { max-height: 220px }
}