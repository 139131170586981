.for-buyers__banner {
  width: 100%;
  max-width: 1920px;
  height: 180px;
  background-color: var(--accent-color);
}

@media screen and (max-width: 768px) {
  .for-buyers__banner  {
    height: 120px;
  }
}