.gallery__object-name {
  font-size: 20px;
  font-weight: 600;
  margin: 0 5px;
}

@media screen and (max-width: 850px) {
  .gallery__object-name {
    font-size: 16px;
  }
}

@media screen and (max-width: 585px) {
  .gallery__object-name {
    font-size: 14px;
  }
}