.puzzles__text {
  width: 100%;
  margin: 50px 0;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 1280px) {
  .puzzles__text {
    width: 90%;
  }
}