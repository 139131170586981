.stage__image {
  width: 550px;
  height: 387px;
  border-radius: 20px;
  object-fit: cover;
}

@media screen and (max-width: 1160px) {
  .stage__image {
    width: calc(100vw - 24px);
  }
}