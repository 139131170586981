.contacts__contacts {
  max-width: 500px;
  background-color: #abb9a4;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  color: var(--secondary-text-color);
  text-shadow: 1px 1px darkgrey;
  padding: 30px;
}