.review-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transition: all .5s ease-out;
}

.review-link:hover {
  transform: scale(1.2);
}