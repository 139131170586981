.more__creation {
  background-image: url(../../../../../images/creation.png);
  background-size: cover;
}

@media screen and (max-width: 900px) {
  .more__creation {
    max-width: 100%;
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

@media screen and (max-width: 540px) {
  .more__creation {
    width: 90%;
  }
}