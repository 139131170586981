.catalog-classes__houses {
  background-image: url(../../../../../images/houses5.jpg);
  background-size: cover;
  grid-column: 1/3;
  grid-row: 3/4;
  pointer-events: auto;
}

@media screen and (max-width: 1020px) {
  .catalog-classes__houses {
    grid-column: 2;
    grid-row: 3;
  }
}