.bestsellers__button {
  color: #778a64;
  font-size: 16px;
  line-height: 1.20;
  font-weight: 500;
  border-width: 1px;
  border-radius: 10px;
  background-color: #ffffff;
  background-position: center center;
  border-color: #8f9e81;
  border-style: solid;
  width: 170px;
  height: 60px;
  /* transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out; */
}

.bestsellers__button:hover {
  color: var(--secondary-text-color);
  border: none;
  background-color: var(--accent-color);
}