.discount__description-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding-left: 50px;
  width: 100%;
  max-width: 622px;
  height: 494px;
  justify-content: center;
  border-width: 1px;
  border-radius: 20px;
  background-color: #f7f5f2;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}