.cart__title {
  width: 100%;
  max-width: 1160px;
  color: var(--primary-text-color);
  font-style: normal;
  font-weight: 500;
  font-size:46px;
  margin: 50px 0 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #a3a3a3;
}

@media screen and (max-width: 1249px) {
  .cart__title {
    max-width: 90%;
  }
}

@media screen and (max-width: 650px) {
  .cart__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 430px) {
  .cart__title {
    font-size: 32px;
  }
}