.comments__button {
  margin-top: 50px;
  background-color: var(--accent-color);
  border-radius: 10px;
  border: none;
  width: 270px;
  height: 60px;
  color: var(--secondary-text-color);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.comments__button:hover {
  color: var(--accent-color);
  border: 1px solid #8f9e81;
  background-color: var(--secondary-text-color);
}