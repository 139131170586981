.card-info__container {
  display: flex;
  width: 100%;
  max-width: 1160px;
  gap: 50px;
  margin-top: 70px;
}

@media screen and (max-width: 1249px) {
  .card-info__container {
    width: 90%;
  }
}

@media screen and (max-width: 980px) {
  .card-info__container {
    flex-direction: column-reverse;
    align-items: center;
    gap: 8px;
  }
}