.contacts__address {
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 1160px) {
  .contacts__address {
    padding: 0 12px;
  }
}