.catalog__items {
  width: 100%;
  max-width: 1160px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(555px, 1fr));
  gap: 20px;
}

@media screen and (max-width: 1249px) {
  .catalog__items {
    max-width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}

@media screen and (max-width: 959px) {
  .catalog__items {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}