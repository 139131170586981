.root {
  font-family: 'Gilroy', 'Arial', sans-serif;
  letter-spacing: 0.4px;
  background-color: var(--primary-bg);
  color: var(--primary-text-color);
  margin: 0;
}

.accent {
  color: var(--accent-color);
  font-weight: 500;
}
