.kitchens__quiz-title {
  margin: 80px 0 0;
  padding: 0 50px;
  font-size: 24px;
  color: var(--secondary-text-color);
  text-align: center;
  font-weight: 600;
  text-shadow: 1px 1px var(--primary-text-color);
}

@media screen and (max-width: 950px) {
  .kitchens__quiz-title {
    font-size: 22px;
  }
}