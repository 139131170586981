.card-info__quantity-container {
  display: flex;
  color: #778a64;
  font-size: 16px;
  line-height: 1.20;
  font-weight: 500;
  border-width: 1px;
  border-radius: 10px;
  background-color: #ffffff;
  background-position: center center;
  border-color: #8f9e81;
  border-style: solid;
  width: 124px;
  height: 45px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
