.catalog__item-title {
  color: var(--secondary-text-color);
  margin: 0;
  font-weight: 600;
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
}

@media screen and (max-width: 1249px) {
  .catalog__item-title {
    font-size: 20px;
  }
}