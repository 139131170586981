.more__items {
  display: flex;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  gap: 25px;
  margin-top: 50px;
}

@media screen and (max-width: 900px) {
  .more__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .more__items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
}