@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: url(../_gilroy/Gilroy-Light.woff) format('woff'),
    url(../_gilroy/Gilroy-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: url(../_gilroy/Gilroy-Regular.woff) format('woff'),
    url(../_gilroy/Gilroy-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: url(../_gilroy/Gilroy-Medium.woff) format('woff'),
    url(../_gilroy/Gilroy-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: url(../_gilroy/Gilroy-Bold.woff) format('woff'),
    url(../_gilroy/Gilroy-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  src: url(../_gilroy/Gilroy-ExtraBold.woff) format('woff'),
       url(../_gilroy/Gilroy-ExtraBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src: url(../_gilroy/Gilroy-Heavy.woff) format('woff'),
    url(../_gilroy/Gilroy-Heavy.woff2) format('woff2');
}