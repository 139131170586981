.cart__contact-form {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}

@media screen and (max-width: 980px) {
  .cart__contact-form {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
}