.now-items {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

@media screen and (max-width: 1160px) {
  .now-items {
    padding: 0 12px;
  }
}