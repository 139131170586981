.policy__title {
  color: var(--accent-color);
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  letter-spacing: 1.1px;
  margin: 0;
  padding: 0 0 8px 0;
  max-width: 80%;
  /* text-shadow: 1px 1px var(--secondary-bg-variant); */
}