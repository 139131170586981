.header {
  position: fixed;
  max-width: 1160px;
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 126px;
  box-sizing: border-box;
  margin-top: 20px;
  background-color: var(--primary-bg);
  border: none;
  border-radius: 10px;
  z-index: 1;
}

@media screen and (max-width: 1600px) {
  .header {
    padding: 0 var(--1280-resolution-padding);
  }
}

@media screen and (max-width: 1249px) {
  .header {
    width: 90%;
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 30px;
    background-color: transparent;
    justify-content: flex-end;
  }
}
