.kitchens__quiz-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1160px;
  margin-top: 70px;
  gap: 20px;
}


@media screen and (max-width: 1249px) {
  .kitchens__quiz-block {
    width: 90%;
  }
}

@media screen and (max-width: 880px) {
  .kitchens__quiz-block {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}