.contacts__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1160px;
  margin-top: 30px;
}

@media screen and (max-width: 1249px) {
  .contacts__container {
    width: 90%;
  }
}