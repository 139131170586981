.decor__text {
  width: 100%;
  margin: 50px 0;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 1249px) {
  .decor__text {
    max-width: 90%;
  }
}