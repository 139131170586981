.cart__total-pic {
  width: 120px;
}


@media screen and (max-width: 490px) {
  .cart__total-pic {
    width: 0;
    position: absolute;
    visibility: hidden;
  }
}