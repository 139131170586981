.contact-form__button {
  margin-top: 50px;
  height: 58px;
  background-color: var(--accent-color);
  color: var(--secondary-text-color);
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
}

@media screen and (max-width: 430px) {
  .contact-form__button {
    margin-top: 35px;
  }
}
