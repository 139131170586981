.for-buyers__container {
  display: flex;
  width: 100%;
  max-width: 1160px;
  margin-top: 70px;
  gap: 50px;
}

@media screen and (max-width: 820px) {
  .for-buyers__container {
    flex-wrap: wrap-reverse;
    justify-content: center;
    padding: 0 12px;
  }
}