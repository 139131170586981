.carousel__next-button {
  position: absolute;
  top: -100px;
  transform: translateY(-50%);
  background-color: #abb9a4;
  color: #fff;
  border: none;
  cursor: pointer;
  right: -15px;
  padding: 0;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 30px;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;
}

.carousel__next-button:hover {
  background-color: var(--accent-color);
}