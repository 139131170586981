.houses__gallery-pic5 {
  background-image: url(../../../../images/house12.jpg);
  background-size: cover;
}

/* @media screen and (max-width: 839px) {
  .houses__gallery-pic5 {
    grid-column: 1/4;
    grid-row: 4/4;
  }
} */