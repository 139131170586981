.mission__block {
  display: flex;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

@media screen and (max-width: 1249px) {
  .mission__block {
    max-width: 90%;
  }
}

@media screen and (max-width: 910px) {
  .mission__block {
    flex-wrap: wrap;
  }
}