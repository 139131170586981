.popup__save-button {
  width: 100%;
  max-width: 358px;
  height: 50px;
  background-color: var(--accent-color);
  color: white;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 48px auto 37px;
  cursor: pointer;
  padding: 0;
}

@media screen and (max-width: 420px) {
  .popup__save-button {
    margin: 45px auto 25px;
    height: 46px;
    font-size: 14px;
    line-height: 17px;
  }
}

.popup__save-button:hover {
  opacity: 0.8;
}
