.banner__container {
  display: flex;
  width: 100%;
  max-width: 1160px;
  align-items: flex-start;
  margin-top: 220px;
  gap: 100px;
}

@media screen and (max-width: 1280px) {
  .banner__container {
    margin-top: 160px;
    width: 90%;
    gap: 40px;
  }
}

@media screen and (max-width: 950px) {
  .banner__container {
    margin-top: 120px;
  }
}

@media screen and (max-width: 750px) {
  .banner__container {
    margin-top: 100px;
  }
}