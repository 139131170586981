.history__about {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 730px;
}

@media screen and (max-width: 1160px) {
  .history__about {
    padding-left: 24px;
  }
}