.more__about {
  background-image: url(../../../../../images/about.png);
  background-size: cover;
}

@media screen and (max-width: 900px) {
  .more__about {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

@media screen and (max-width: 540px) {
  .more__about {
    width: 90%;
    max-width: 500px;
  }
}