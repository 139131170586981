.electricity__title {
  margin: 50px 0 0;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  max-width: 1160px;
  width: 100%;
}