.gallery__title {
  width: 100%;
  max-width: 1160px;
  margin: 100px 0 20px;
  font-size: 36px;
  font-weight: 500;
}

@media screen and (max-width: 1249px) {
  .gallery__title {
    width: 90%;
  }
}

@media screen and (max-width: 850px) {
  .gallery__title {
    font-size: 24px;
    font-weight: 600;
  }
}