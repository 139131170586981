.puzzles__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 11px;
  width: 100%;
  text-align: center;
  position: relative;

  /* content-visibility: hidden; */
}

@media screen and (max-width: 1280px) {
  .puzzles__filter {
    width: 90%;
    justify-content: center;
  }
}