.kitchens__quiz-pic {
  width: 84px;
  height: 84px;
  align-self: center;
  padding: 50px;
}

@media screen and (max-width: 950px) {
  .kitchens__quiz-pic {
    width: 60px;
    height: 60px;
  }
}