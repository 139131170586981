.cart__total-delete {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 950px) {
  .cart__total-delete {
    width: 32px;
  }
}

@media screen and (max-width: 490px) {
  .cart__total-delete {
    width: 25px;
  }
}

@media screen and (max-width: 430px) {
  .cart__total-delete {
    width: 22px;
  }
}
