.contact-form {
  display: flex;
  flex-direction: column;
  width: 80%;
}

@media screen and (max-width: 430px) {
  .contact-form  {
    width: 90%;
  }
}
