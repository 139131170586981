.houses__filter-title {
  margin: 30px 0 0;
  color: var(--primary-text-color);
  font-size: 40px;
  font-weight: 600;
}

@media screen and (max-width: 1249px) {
  .houses__filter-title {
    visibility: hidden;
    height: 0;
    width: 90%;
  }
}