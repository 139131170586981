.popup {
  font-family: inherit;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}