.size__item {
  width: 75px;
  height: 67px;
  border-radius: 10px;
  border: 1px solid var(--accent-color);
  font-size: 22px;
  font-weight: 600;
  color: var(--accent-color);
}

.size__item:hover {
  color: var(--secondary-text-color);
  background-color: var(--accent-color);

}

button.read-more-info-icon {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
button.read-more-info-icon:hover {
  overflow: visible;
}
button.read-more-info-icon .hidden-info {
  position: absolute;
  top: 100px;
  width: 240px;
  text-align: center;
  background: var(--accent-color);
  padding: 10px;
  border-radius: 10px;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  color:#fff;
  font-size: 20px;
  font-weight: 500;
}
button.read-more-info-icon.modal-left .hidden-info { 
  right: 0;
}
button.read-more-info-icon.modal-right .hidden-info { 
  left: 0;
}
button.read-more-info-icon:hover .hidden-info {
  opacity: 1;
}