.catalog-classes__item {
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 20px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: transparent;
  border-style: solid;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 650px) {
  .catalog-classes__item {
    height: 90%;
  }
}
