.cart__info-title {
  margin: 5px;
}

@media screen and (max-width: 950px) {
  .cart__info-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 645px) {
  .cart__info-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .cart__info-title {
    font-size: 13px;
  }
}