.catalog-classes__items {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  grid-auto-rows: minmax(0px, 320px);
  width: 100%;
  height: 912px;
  max-width: 1160px;
  margin-top: 50px;
}

@media screen and (max-width: 1249px) {
  .catalog-classes__items {
    max-width: 90%;
  }
}

@media screen and (max-width: 1020px) {
  .catalog-classes__items {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 610px) {
  .catalog-classes__items {
    display: flex;
    flex-direction: column;
  }
}

